import { createApp } from 'vue'
import Icon from '~/src/components/Icon.vue'
import App from '~/src/App.vue'
import { createRouter } from '~/src/router'
import { SetLanguageMutation, createStore } from '~/src/store'
import type { Language } from "hto-api-client"
import { DefaultApi, Configuration } from 'hto-api-client'
import { createTranslation } from './composables/useTranslation'

const storeLanguage = localStorage.getItem('language')
const userLanguage: Language = storeLanguage === "fi" || storeLanguage === "en" ? storeLanguage : 'fi'
const api = new DefaultApi(new Configuration({ basePath: process.env.API_BASE_URL }))
const router = createRouter()
const [store, storeKey] = createStore(appConfig, api)
store.commit(new SetLanguageMutation(userLanguage))
const [translation, translationKey] = createTranslation(
  () => store.state.lang,
  store.state.config.translationsOverrides
)

createApp(App)
  .component('icon', Icon)
  .use(router)
  .use(store, storeKey)
  .provide(translation, translationKey)
  .mount('#root')
