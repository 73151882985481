<template>
  <router-link
    class="d-flex justify-content-end align-items-center"
    :data-date="day.date"
    :class="dayClasses"
    :to="dateRoute"
  >
    <div class="text-nowrap mr-2">
      <span class="weekday text-capitalize mr-2 notranslate" :style="dayStyle">{{ weekdayFormatting(day.date, lang) }}</span>
      <time :datetime="day.date" >{{ dayFormatting(day.date) }}</time>
    </div>
    <div class="note text-truncate text-nowrap" data-cy="note-heading">{{ dayNotes.heading }}</div>
    <icon v-if="dayNotes.details" class="note ml-2" name="bookmark" data-cy="note-details" />
    <div role="separator" class="mr-auto" />
    <div v-if="!day.canceled" class="counters text-right text-nowrap ml-2 mr-2">
      <happy-counter :day="day" />
    </div>
    <icon-cessna v-if="!day.canceled" :color="pilot ? 'black' : '#f9f9f9'" />
    <icon v-else name="ban" data-cy="ban" class="ml-2 canceled" />
  </router-link>
</template>

<script lang="ts">
import type { PropType, StyleValue } from 'vue'
import { defineComponent, h, toRef, computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { Day } from 'hto-api-client'
import IconCessna from '~/src/components/IconCessna.vue'
import { dayFormatting, weekdayFormatting } from '~/src/util'
import { dayNotesLens } from '~/src/composables/useDay'
import { useStore } from '~/src/store'

const HappyCounter = defineComponent({
  props: {
    day: {
      required: true,
      type: Object as PropType<Day>
    }
  },
  render() {
    const { happyCount, unhappyCount } = this.day
    const happy = h('span', { class: 'happy' }, happyCount)
    const unhappy = h('span', { class: 'unhappy' }, unhappyCount)
    if (unhappyCount > 0) {
      return [happy, ' / ', unhappy]
    } else {
      return happy
    }
  }
})

export default defineComponent({
  props: {
    day: {
      required: true,
      type: Object as PropType<Day>
    },
    color: String
  },
  setup(props) {
    const { state } = useStore()
    const day = toRef(props, 'day')
    return {
      lang: state.lang,
      dayNotes: computed(() => dayNotesLens(day.value))
    }
  },
  computed: {
    dateRoute(): RouteLocationRaw {
      return {
        name: 'date',
        params: { date: this.day.date }
      }
    },
    pilot(): boolean {
      return this.day.pilotCount > 0
    },
    dayClasses(): Record<string, boolean> {
      const SUNDAY = 0
      return {
        day: true,
        disabled: this.day.canceled,
        empty: this.day.happyCount + this.day.unhappyCount === 0,
        'end-of-week': new Date(this.day.date).getDay() === SUNDAY
      }
    },
    dayStyle(): StyleValue | undefined {
      return this.color ? { backgroundColor: this.color } : undefined
    }
  },
  methods: {
    dayFormatting,
    weekdayFormatting
  },
  components: {
    IconCessna,
    HappyCounter
  }
})
</script>

<style lang="scss" scoped>
@import '../assets/var.scss';

$height: 32px;
$weekday-name-color: #333;
$text-color: #aaa;

.day {
  border-bottom: 1px solid $separator-boder-color;
  padding: 0.75em;
  color: $weekday-name-color;
  text-decoration: none;
  line-height: $height;

  &:hover {
    text-decoration: none;
    color: $weekday-name-color;
  }

  &.disabled, &.empty .counters {
    color: $text-color;

    svg.canceled {
      color: red;
      vertical-align: middle;
      /* Same size as the plane icon */
      min-height: 26px;
      min-width: 26px;
      max-height: 26px;
      max-width: 26px;
    }
  }

  .additional-notes {
    height: $height;
    vertical-align: top;
  }

  .weekday {
    font-size: 0.9rem;
    font-variant: small-caps;
    display: inline-block;
    text-align: center;
    width: $height;
    height: $height;
    min-width: $height;
    min-height: $height;
    border-radius: 100%;
  }

  &.end-of-week {
    border-bottom: 2px solid #bbb;
  }

  .note, time {
    color: $text-color;
  }
}

:deep(.unhappy) {
  color: red;
}
</style>
