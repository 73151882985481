<template>
  <div v-if="show" class="notes" :class="klass">
    <dynamic tag="h1" :children="heading" class="m-0" />
    <dynamic tag="div" :children="details" class="d-inline" />
    <read-more v-if="showReadMore" @click="showAll = !showAll" :showAll="showAll" />
  </div>
</template>

<script lang="ts">
import { dayNotesLens } from '~/src/composables/useDay'
import type { Day } from 'hto-api-client'
import type { PropType} from 'vue'
import { computed, defineComponent, h, ref, toRef } from 'vue'

export default defineComponent({
  name: 'DayNotes',
  props: {
    day: {
      type: Object as PropType<Day>,
      required: true
    }
  },
  setup(props) {
    const day = toRef(props, 'day')
    const showAll = ref(false)
    const notes = computed(() => dayNotesLens(day.value))
    const breakpoint = 180 // character limit
    const index = computed(() => notes.value.details.substr(breakpoint).search(/\s/))

    return {
      show: computed(() => day.value.canceled || day.value.notes.length > 0),
      showAll,
      showReadMore: computed(() => index.value >= 0),
      klass: computed(() => ({ 'day-canceled': day.value.canceled })),
      heading: computed(() => replaceLinksAndLineBreaksWithVNodes(notes.value.heading || (day.value.canceled ? 'Ei toimintaa' : ''))),
      details: computed(() => showAll.value || index.value < 0
        ? replaceLinksAndLineBreaksWithVNodes(notes.value.details)
        : replaceLinksAndLineBreaksWithVNodes(notes.value.details.substr(0, breakpoint + index.value))
      ),
    }
  },
  components: {
    Dynamic: defineComponent({
      props: ['tag', 'children'],
      render() { return h(this.tag, this.children) }
    }),
    ReadMore: defineComponent({
      props: {
        showAll: {
          type: Boolean,
          required: true
        }
      },
      render() {
        const props = { class: ['read-more', 'text-nowrap'] }
        if (this.showAll) return h('strong', props, [' ', 'Piilota'])
        else return h('span', props, ['... ', h('strong', 'Lue lisää')])
      }
    })
  }
})

/**
 * First capturing group is a link starting with http(s)://
 * Note: while loop relies to there being two capturing groups.
 */
function replaceLinksAndLineBreaksWithVNodes(row: string) {
  if (row.length === 0) return []
  
  const childrens = []
  let from = 0

  let match
  const LINK_OR_LINE_BREAK = /(https?:\/\/[a-zA-Z0-9@:%._+~#=]{3,253}\b[-a-zA-Z0-9@:%_+.~#?&//=]*)|(\n)/gm
  while ((match = LINK_OR_LINE_BREAK.exec(row)) !== null) {
    const text = row.substr(from, match.index - from)
    if (match[1] !== undefined) {
      const href = match[1]
      const link = h('a', { href, rel: "nofollow noopener", role: "link", target: "_blank" }, href)
      childrens.push(text, link)
    }
    else if (match[2] !== undefined) {
      childrens.push(text, h('br'))
    }
    from = match.index + match[0].length
  }
  childrens.push(row.substr(from))
  return childrens
}
</script>

<style lang="scss" scoped>
@import '../assets/var.scss';

.notes {
  font-size: 1em;
  border-bottom: 1px solid $separator-boder-color;

  :deep(h1) {
    font-size: 1.2em;
  }
}

.notes.day-canceled :deep(h1) {
  color: red;
}

.read-more {
  cursor: pointer;
}
</style>