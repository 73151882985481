<template>
  <div class="lds-ripple" data-cy="spinner">
    <div :style="style"></div>
    <div :style="style"></div>
  </div>
</template>

<style lang="scss" scoped>
.lds-ripple {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
}
.lds-ripple div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'spinner',
  computed: {
    style() {
      return {
        borderColor: this.$store.state.config.clubColor
      } 
    }
  }
})
</script>
