<template>
  <div class="form-group">
    <form-label for="name" :required="true">{{ t('name.label') }}</form-label>
    <input type="input" class="form-control" id="name" name="fname" data-cy="fname"
           v-model="modelValue"
           required maxlength="48" autocomplete="given-name" />
  </div>
</template>

<script setup lang="ts">
import FormLabel from '~/src/components/FormLabel.vue'
import { useTranslation } from '~/src/composables/useTranslation'

const { t } = useTranslation()
const modelValue = defineModel<string>({ required: true })
defineOptions({ components: { FormLabel } })
</script>
