<template>
  <add-or-update-person-form :title="t('skydiver.addOrEditLabel')"
    :id="id"
    :date="date"
    :person="skydiver"
  >
    <label>{{ t('roles.label') }}</label>
    <person-flag-selector v-for="key in skydiverRoles" :label="t(roleLabelsKeys[key])" v-model="skydiver.flags[key]" :data-cy="key" :key="key" />
  </add-or-update-person-form>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import AddOrUpdatePersonForm from '~/src/components/AddOrUpdatePersonForm.vue'
import PersonFlagSelector from '~/src/components/PersonFlagSelector.vue'
import { usePerson, useStubPerson } from '~/src/composables/usePerson'
import { PersonRoleEnum, TranslationKey } from 'hto-api-client'
import { ValidSkydiverRole, useStore } from '~/src/store'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  setup(props) {   
    const { t } = useTranslation()
    const { date, id } = toRefs(props)
    const { state } = useStore()

    return {
      t,
      roleLabelsKeys,
      skydiver: useStubPerson(usePerson(date, id), PersonRoleEnum.Skydiver),
      skydiverRoles: state.config.skydiverRoles
    }
  },
  props: {
    id: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    }
  },
  components: {
    AddOrUpdatePersonForm,
    PersonFlagSelector
  }
})

const roleLabelsKeys: Record<ValidSkydiverRole, TranslationKey> = {
  instructorAff: 'affInstructor.label',
  instructorSl: 'slInstructor.label',
  instructorTandem: 'tandemInstructor.label',
  instructorCoach: 'coach.label',
  instructorRadio: 'radio.label',
  tandemVideo: 'tandemVideo.label'
}
</script>
