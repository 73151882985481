"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonRoleEnumToJSON = exports.PersonRoleEnumFromJSONTyped = exports.PersonRoleEnumFromJSON = exports.PersonRoleEnum = void 0;
/**
 *
 * @export
 */
exports.PersonRoleEnum = {
    Skydiver: 'skydiver',
    Student: 'student',
    Pilot: 'pilot',
    Support: 'support'
};
function PersonRoleEnumFromJSON(json) {
    return PersonRoleEnumFromJSONTyped(json, false);
}
exports.PersonRoleEnumFromJSON = PersonRoleEnumFromJSON;
function PersonRoleEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PersonRoleEnumFromJSONTyped = PersonRoleEnumFromJSONTyped;
function PersonRoleEnumToJSON(value) {
    return value;
}
exports.PersonRoleEnumToJSON = PersonRoleEnumToJSON;
