"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonToJSON = exports.PersonFromJSONTyped = exports.PersonFromJSON = exports.instanceOfPerson = void 0;
const runtime_1 = require("../runtime");
const Flags_1 = require("./Flags");
const PersonRoleEnum_1 = require("./PersonRoleEnum");
const StudentStageEnum_1 = require("./StudentStageEnum");
/**
 * Check if a given object implements the Person interface.
 */
function instanceOfPerson(value) {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "readyTime" in value;
    isInstance = isInstance && "studentStage" in value;
    isInstance = isInstance && "flags" in value;
    return isInstance;
}
exports.instanceOfPerson = instanceOfPerson;
function PersonFromJSON(json) {
    return PersonFromJSONTyped(json, false);
}
exports.PersonFromJSON = PersonFromJSON;
function PersonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'notes': json['notes'],
        'role': (0, PersonRoleEnum_1.PersonRoleEnumFromJSON)(json['role']),
        'happyNotes': !(0, runtime_1.exists)(json, 'happyNotes') ? undefined : json['happyNotes'],
        'unhappyNotes': !(0, runtime_1.exists)(json, 'unhappyNotes') ? undefined : json['unhappyNotes'],
        'readyTime': json['readyTime'],
        'studentStage': (0, StudentStageEnum_1.StudentStageEnumFromJSON)(json['studentStage']),
        'flags': (0, Flags_1.FlagsFromJSON)(json['flags']),
    };
}
exports.PersonFromJSONTyped = PersonFromJSONTyped;
function PersonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'notes': value.notes,
        'role': (0, PersonRoleEnum_1.PersonRoleEnumToJSON)(value.role),
        'happyNotes': value.happyNotes,
        'unhappyNotes': value.unhappyNotes,
        'readyTime': value.readyTime,
        'studentStage': (0, StudentStageEnum_1.StudentStageEnumToJSON)(value.studentStage),
        'flags': (0, Flags_1.FlagsToJSON)(value.flags),
    };
}
exports.PersonToJSON = PersonToJSON;
