import type { Day, Person } from "hto-api-client"
import type { RootState } from "~/src/store"

export const findDay = (state: RootState) => (date: string): Day | undefined => {
  const day = state.days.find(d => d.date === date)
  return day && day.jumpers && day.pilots ? day : undefined
}

export const findPerson = (state: RootState) => (date: string, id: string): Person | undefined => {
  const day: Day | undefined = findDay(state)(date)
  if (!day) return undefined
  
  let person = day.jumpers.find(p => p.id === id)
  if (person) return person
  person = day.pilots.find(p => p.id === id)
  return person
}
