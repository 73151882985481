<template>
  <time :datetime="date" class="text-truncate text-nowrap">
    <span class="text-capitalize">{{ weekdayFormatting(date, lang, 'short') }}</span>
    <span class="d-none d-sm-inline">{{ weekdayFormatting(date, lang, 'long').replace(weekdayFormatting(date, lang, 'short'), '') }}</span>
    {{ fullDayFormatting(date) }}
  </time>
  <sun-component v-if="sunset" :sunset="sunset" class="ml-2 text-nowrap" />
</template>

<script lang="ts">
import { defineComponent, toRef, computed } from 'vue'
import { useDay } from '~/src/composables/useDay'
import { fullDayFormatting, weekdayFormatting } from '~/src/util'

import SunComponent from '~/src/components/SunComponent.vue'
import { useStore } from '~/src/store'

export default defineComponent({
  props: {
    date: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { state } = useStore()
    const day = useDay(toRef(props, 'date'))
    return {
      lang: state.lang,
      sunset: computed(() => day.value?.sun.sunset),
      notes: computed(() => day.value?.notes),
      canceled: computed(() => day.value?.canceled),
      fullDayFormatting,
      weekdayFormatting
    }
  },
  components: {
    SunComponent
  }
})
</script>

<style lang="scss" scoped>
time {
  font-weight: bold;
}
</style>