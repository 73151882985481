<template>
  <navbar class="justify-content-between">
    <template v-slot:banner>
      <div class="d-flex logo">
        <img :src="logo" :alt="logoAlt" data-cy="logo" />
      </div>
    </template>
    <template v-slot:actions>
      <div class="d-flex">
        <div class="logo-fader">&nbsp;</div>
        <action-button :action="help" />
      </div>
    </template>
  </navbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Navbar from '~/src/navbar/Navbar.vue'
import { useStore } from '~/src/store'
import { help } from '~/src/navbar/actions'
import ActionButton from '~/src/navbar/ActionButton.vue'

export default defineComponent({
  setup() {
    const { state } = useStore()
    return {
      logo: state.config.logo,
      logoAlt: state.config.logoAlt,
      help: help()
    }
  },
  components: {
    Navbar,
    ActionButton
  }
})
</script>

<style lang="scss" scoped>
@import '../assets/var.scss';

.logo {
  align-items: center;
  overflow: hidden;
  img {
    height: 24px;
    max-width: 10000%;
  }
}

/* nice fade to background if logo does not fit */
.logo-fader {
  background: $global-background-color;
  box-shadow: -.5rem 0 .5rem .5rem $global-background-color;
  width: .5rem;
}
</style>
