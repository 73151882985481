<template>
  <button type="button" class="btn" data-cy="copy-day">
    {{ t("day.copyPreviousDayLabel") }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  setup() {
    const { t } = useTranslation()
    return { t }
  }
})
</script>
