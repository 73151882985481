import type { ComputedRef, Ref } from 'vue'
import { computed } from 'vue'

import pencil_alt from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/pencil-alt.svg'
import trash from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/trash.svg'
import question_circle from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/question-circle.svg'
import ban from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/ban.svg'
import arrow_left from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/arrow-left.svg'
import arrow_right from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/arrow-right.svg'
import frown from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/frown.svg'
import smile from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/smile.svg'
import sun from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/sun.svg'
import check_circle from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/check-circle.svg'
import carrot from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/carrot.svg'
import bullhorn from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/bullhorn.svg'
import bookmark from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/bookmark.svg'
import exclamation_circle from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/exclamation-circle.svg'
import eye from 'bundle-text:@fortawesome/fontawesome-free/svgs/solid/eye.svg'
import cessna from 'bundle-text:~/src/assets/cessna.svg'

const icons: Record<string, string> = {
  'pencil-alt': pencil_alt,
  'trash': trash,
  'question-circle': question_circle,
  'ban': ban,
  'arrow-left': arrow_left,
  'arrow-right': arrow_right,
  'frown': frown,
  'smile': smile,
  'sun': sun,
  'check-circle': check_circle,
  'carrot': carrot,
  'bullhorn': bullhorn,
  'bookmark': bookmark,
  'exclamation-circle': exclamation_circle,
  'cessna': cessna,
  'eye': eye,
}

export function useIcon(name: Ref<string>): ComputedRef<string> {
  return computed(() => {
    if (name.value in icons) return icons[name.value]
    // eslint-disable-next-line no-console
    console.warn(`No '${name.value}' icon registered`)
    return ''
  })
}
