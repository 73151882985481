<template>
  <div class="form-group">
    <form-label for="readyTime">{{ t('readyTime.label') }}</form-label>
    <input class="form-control" id="readyTime" data-cy="ready-time"
            v-model="modelValue"
            maxlength="32"/>
  </div>
</template>

<script setup lang="ts">
import FormLabel from '~/src/components/FormLabel.vue'
import { useTranslation } from '~/src/composables/useTranslation'

const { t } = useTranslation()
const modelValue = defineModel<string>({ default: '' })
defineOptions({ components: { FormLabel } })
</script>
