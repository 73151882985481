import { LoadDayPayload, useStore } from "~/src/store"
import type { Day } from 'hto-api-client'
import { findDay } from "~/src/store/getters"
import type { ComputedRef, Ref} from "vue"
import { computed, readonly, ref, watchEffect } from "vue"

export function useDay(date: Ref<string | undefined>): ComputedRef<Day | undefined> {
  const { state } = useStore()
  return computed(() => {
    return date && date.value ? findDay(state)(date.value) : undefined
  })
}

export enum Status {
  READY,
  LOADING,
  ERROR
}

/** Loads day data for `date` if its not found from store. */
export function loadDayEffect(date: Ref<string>) {
  const { state, dispatch } = useStore()

  const errorMessage = ref('')
  const status = ref(Status.READY)

  watchEffect(async () => {   
    if (findDay(state)(date.value) == undefined) {
      try {
        status.value = Status.LOADING
        // Populates the people field of day and hides "loading" text
        await dispatch(new LoadDayPayload(date.value))
        status.value = Status.READY
        errorMessage.value = ''
      } catch (e) {
        status.value = Status.ERROR
        errorMessage.value = e instanceof Error ? e.message : 'Error'
      }
    }
  })

  return {
    status: readonly(status),
    errorMessage: readonly(errorMessage)
  }
}

export function dayNotesLens(day: Day): { heading: string; details: string } {
  const [text, ...additionalText] = day.notes.split('\n')
  return {
    heading: (text || '').trim(),
    details: (additionalText.join('\n')).trim()
  }
}
