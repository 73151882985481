<template>
  <add-or-update-person-form :title="t('student.addOrEditLabel')"
    :id="id"
    :date="date"
    :person="student"
  >
    <div class="form-group">
      <form-label for="student-stage" :required="true">{{ t("student.stageLabel") }}</form-label>
      <select class="form-control" id="student-stage" v-model="student.studentStage" data-cy="student-stage">
        <option v-for="[stage, label] in studentStages" :key="stage" :value="stage">
          {{ label }}
        </option>
      </select>
    </div>
    <person-flag-selector :label="t('student.needRadioInstructorLabel')" v-model="student.flags.studentRadio" data-cy="studentRadio" />
  </add-or-update-person-form>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import AddOrUpdatePersonForm from '~/src/components/AddOrUpdatePersonForm.vue'
import PersonFlagSelector from '~/src/components/PersonFlagSelector.vue'
import FormLabel from '~/src/components/FormLabel.vue'
import { PersonRoleEnum, TranslationKey } from 'hto-api-client'
import { usePerson, useStubPerson } from '~/src/composables/usePerson'
import { ValidStudentStage, useStore } from '~/src/store'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  props: {
    id: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { t } = useTranslation()
    const { date, id } = toRefs(props)
    const { config } = useStore().state

    const translationKeyMap: Record<ValidStudentStage, TranslationKey> = {
      aff1_3: 'student.stageAff13',
      aff4_7: 'student.stageAff47',
      sl: 'student.stageSl',
      basic: 'student.stageBasic',
      tandem: 'student.stageTandem'
    }

    return {
      t,
      student: useStubPerson(usePerson(date, id), PersonRoleEnum.Student, config.studentStages[0]),
      studentStages: config.studentStages.map((stage) => [stage, t(translationKeyMap[stage])])
    }
  },
  components: {
    AddOrUpdatePersonForm,
    PersonFlagSelector,
    FormLabel
  }
})
</script>
