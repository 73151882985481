import type { Payload } from 'vuex'
import type { Language, StudentStageEnum, TranslationKey, Translations } from 'hto-api-client'
import type {
  Day,
  UpdateDay,
  Person,
  PersonRoleEnum,
  Flags
} from 'hto-api-client'

export class LoadTimelinePayload implements Payload {
  public readonly type = 'loadTimeline'
  public readonly since?: string
  public readonly count?: number
  public readonly countBack?: number
  public constructor(since?: string, count?: number, countBack?: number) {
    this.since = since
    this.count = count
    this.countBack = countBack
  }
}

export class LoadDayPayload implements Payload {
  public readonly type = 'loadDay'
  public readonly date: string
  public constructor(date: string) {
    this.date = date
  }
}

export class UpdateDayPayload implements Payload {
  public readonly type = 'updateDay'
  public readonly date: string
  public readonly update: UpdateDay
  public constructor(date: string, update: UpdateDay) {
    this.date = date
    this.update = update
  }
}

export class AddPersonPayload implements Payload {
  public readonly type = 'addPerson'
  public readonly date: string
  public readonly person: Person
  public readonly showToast: boolean
  public constructor(date: string, person: Person, showToast: 'showToast' | 'noToast') {
    this.date = date
    this.person = person
    this.showToast = showToast === 'showToast'
  }
}

export class DeletePersonPayload implements Payload {
  public readonly type = 'deletePerson'
  public readonly date: string
  public readonly id: string
  public constructor(date: string, id: string) {
    this.date = date
    this.id = id
  }
}

export class ShowToastPayload implements Payload {
  public readonly type = 'showToast'
  public readonly message: TranslationKey
  public readonly duration: number
  public readonly undo: Payload[]
  public constructor(message: TranslationKey, undo: Payload[], duration: number) {
    this.message = message
    this.duration = duration
    this.undo = undo
  }
}

export class SetDayMutation implements Payload {
  public readonly type = 'setDay'
  public readonly day: Day | readonly Day[]
  public constructor(day: Day | readonly Day[]) {
    this.day = day
  }
}

export class SetScrollPositionMutation implements Payload {
  public readonly type = 'setScrollPosition'
  public readonly scrollTop: number
  public constructor(scrollTop: number) {
    this.scrollTop = scrollTop
  }
}

export class SetLanguageMutation implements Payload {
  public readonly type = 'setLanguage'
  public constructor(public readonly language: Language) {}
}

export interface RootState {
  today: string;
  days: readonly Day[];
  scrollTop: number | undefined;
  config: HtoConfiguration & ConstConfiguration;
  lang: Language
}

/** Default values for new person forms */
export class StubPerson implements Person {
  public id?: string
  public role: PersonRoleEnum
  public studentStage: StudentStageEnum
  public name = ''
  public notes = ''
  public readyTime = ''
  public flags: Flags = {
    unhappy: false,
    instructorTandem: false,
    instructorAff: false,
    instructorSl: false,
    instructorRadio: false,
    instructorCoach: false,
    studentRadio: false,
    tandemVideo: false
  }
  
  constructor(role: PersonRoleEnum, studentStage: StudentStageEnum) {
    this.role = role
    this.studentStage = studentStage
  }
}

export interface HtoConfiguration {
  /** imported *.svg resource in assets folder */
  logo: string;
  logoAlt: string;
  /** Web page title (top of a browser window) */
  title: string;
  clubColor: string;
  /** The value that is passed to backend API in Skydive-Club-Name header */
  clubName: string;
  studentColor: string;
  studentStages: ValidStudentStage[];
  skydiverRoles: ValidSkydiverRole[];
  translationsOverrides?: Partial<Record<TranslationKey, Translations>>;
}

export type NotStudent = Exclude<PersonRoleEnum, 'student'>
export type ValidStudentStage = Exclude<StudentStageEnum, 'none'>

export type ValidSkydiverRole = keyof Pick<Flags, 
"instructorAff" |
"instructorSl" |
"instructorTandem" |
"instructorCoach" |
"instructorRadio" |
"tandemVideo">
