<template>
    <icon :color="studentColor" name="carrot" data-cy="student-icon" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  computed: {
    studentColor() {
      return this.$store.state.config.studentColor
    }
  }
})
</script>