<template>
  <svg v-html="icon" v-bind="options"></svg>
</template>

<script lang="ts">
import { defineComponent, toRef } from 'vue'
import { useIcon } from '~/src/composables/useIcons'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    color: String
  },
  setup(props) {
    const icon = useIcon(toRef(props, 'name'))
    const viewBox = icon.value.match(/viewBox="\S+ \S+ (\S+) (\S+)"/)
    const width = parseFloat(viewBox?.[1] ?? "0")
    const height = parseFloat(viewBox?.[2] ?? "0")
    const ratio = Math.max(width, height) / 16
    const options: Record<string, unknown> = {
      class: 'fa-icon',
      x: 0,
      y: 0,
      width: width / ratio * props.scale,
      height: height / ratio * props.scale,
      viewBox: `0 0 ${width} ${height}`,
      focusable: false,
      style: { color: props.color ?? false }
    }

    if (props.scale !== 1) {
      options.style = { fontSize: props.scale + 'em'}
    }    

    return {
      icon,
      options
    }
  },
})
</script>

<style lang="scss">
.fa-icon {
  display: inline-block;
  fill: currentColor;
  overflow: visible;
  vertical-align: -0.125em;

  > g {
    transform-origin: 50% 50%;
  }
}
</style>
