<template>
  <footer v-if="toasts.length > 0" class="toaster">
    <div v-for="(toast, index) in toasts" :key="index" class="d-flex m-4">
        <span>{{ t(toast[0].message) }}</span>
        <button class="ml-auto" v-if="toast[0].undo.length > 0" @click="undo(toast)">{{ t('toast.undo') }}</button>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '../assets/var.scss';

footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: $app-max-width;
  z-index: 9999;

  > div {
    background: #333;
    color: white;
    border-radius: .5rem;
    padding: .75em 1em;
  }
}

button {
  background: transparent;
  font-weight: bold;
  text-transform: uppercase;
  color: red;
  border: none;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import { ShowToastPayload } from '~/src/store'
import { useTranslation } from '~/src/composables/useTranslation'

type ToastInfo = [ShowToastPayload, number]

function removeByHandleId(toasts: ToastInfo[], handle: number) {
  return toasts.filter(t => t[1] !== handle)
}

export default defineComponent({
  setup() {
    const { t } = useTranslation()
    return { t }
  },
  data() {
    const toasts: ToastInfo[] = []
    return { toasts }
  },
  mounted() {
    this.$store.subscribeAction((action) => {
      if (!(action.payload instanceof ShowToastPayload)) return

      // Hides toast after timeout
      const handle = window.setTimeout(
        () => { this.toasts = removeByHandleId(this.toasts, handle) },
        action.payload.duration
      )
      
      this.toasts.push([
        action.payload,
        handle
      ])
    })
  },
  methods: {
    async undo([toast, handle]: ToastInfo) {
      clearTimeout(handle)
      this.toasts = removeByHandleId(this.toasts, handle)

      try {
        for (let i = 0; i < toast.undo.length; i++) {
          await this.$store.dispatch(toast.undo[i])
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e)
      }
    }
  }
})
</script>
