"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlagsToJSON = exports.FlagsFromJSONTyped = exports.FlagsFromJSON = exports.instanceOfFlags = void 0;
/**
 * Check if a given object implements the Flags interface.
 */
function instanceOfFlags(value) {
    let isInstance = true;
    isInstance = isInstance && "instructorAff" in value;
    isInstance = isInstance && "instructorCoach" in value;
    isInstance = isInstance && "instructorRadio" in value;
    isInstance = isInstance && "instructorSl" in value;
    isInstance = isInstance && "instructorTandem" in value;
    isInstance = isInstance && "studentRadio" in value;
    isInstance = isInstance && "tandemVideo" in value;
    isInstance = isInstance && "unhappy" in value;
    return isInstance;
}
exports.instanceOfFlags = instanceOfFlags;
function FlagsFromJSON(json) {
    return FlagsFromJSONTyped(json, false);
}
exports.FlagsFromJSON = FlagsFromJSON;
function FlagsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'instructorAff': json['instructorAff'],
        'instructorCoach': json['instructorCoach'],
        'instructorRadio': json['instructorRadio'],
        'instructorSl': json['instructorSl'],
        'instructorTandem': json['instructorTandem'],
        'studentRadio': json['studentRadio'],
        'tandemVideo': json['tandemVideo'],
        'unhappy': json['unhappy'],
    };
}
exports.FlagsFromJSONTyped = FlagsFromJSONTyped;
function FlagsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'instructorAff': value.instructorAff,
        'instructorCoach': value.instructorCoach,
        'instructorRadio': value.instructorRadio,
        'instructorSl': value.instructorSl,
        'instructorTandem': value.instructorTandem,
        'studentRadio': value.studentRadio,
        'tandemVideo': value.tandemVideo,
        'unhappy': value.unhappy,
    };
}
exports.FlagsToJSON = FlagsToJSON;
