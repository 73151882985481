<template>
  <nav class="d-flex align-items-center ml-3 mr-3" v-bind="$attrs">
    <action-button v-if="previous" :action="back" class="nav-back-btn mr-3" data-cy="navigate-back" />
    <slot name="banner"></slot>
    <slot name="actions"></slot>
  </nav>
  <prev-next-navigation v-if="showPrevNext" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import PrevNextNavigation from '~/src/components/PrevNextNavigation.vue'
import ActionButton from '~/src/navbar/ActionButton.vue'
import type { NavigationAction } from '~/src/navbar/actions'

function backButtonAction(previous: string | undefined) {
  return previous === '__back' ? previous : { name: previous }
}

export default defineComponent({
  name: 'navbar',
  props: {
    previous: String,
    showPrevNext: Boolean
  },
  setup(props) {
    const back: NavigationAction = {
      kind: 'action',
      icon: 'arrow-left',
      titleKey: 'nav.back',
      onClick: [backButtonAction(props.previous)]
    }
    return { back }
  },
  components: {
    PrevNextNavigation,
    ActionButton
  }
})
</script>

<style lang="scss" scoped>
nav {
  $height: 3.5em;
  color: #444;
  height: $height;
  min-height: $height;
}

:deep(.nav-btn) {
  display: block;
  color: #777;
  background: transparent;
  border: none;
  z-index: 9999;
  cursor: pointer;
  
  &:focus {
    outline: none;
  }

  &:hover {
    color: #555;
  }

  &.nav-back-btn {
    color: #333;
  }  
}
</style>
