"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayToJSON = exports.DayFromJSONTyped = exports.DayFromJSON = exports.instanceOfDay = void 0;
const DaySun_1 = require("./DaySun");
const Person_1 = require("./Person");
/**
 * Check if a given object implements the Day interface.
 */
function instanceOfDay(value) {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "canceled" in value;
    isInstance = isInstance && "skydiverCount" in value;
    isInstance = isInstance && "studentCount" in value;
    isInstance = isInstance && "pilotCount" in value;
    isInstance = isInstance && "happyCount" in value;
    isInstance = isInstance && "unhappyCount" in value;
    isInstance = isInstance && "pilots" in value;
    isInstance = isInstance && "jumpers" in value;
    isInstance = isInstance && "sun" in value;
    return isInstance;
}
exports.instanceOfDay = instanceOfDay;
function DayFromJSON(json) {
    return DayFromJSONTyped(json, false);
}
exports.DayFromJSON = DayFromJSON;
function DayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'date': json['date'],
        'notes': json['notes'],
        'canceled': json['canceled'],
        'skydiverCount': json['skydiverCount'],
        'studentCount': json['studentCount'],
        'pilotCount': json['pilotCount'],
        'happyCount': json['happyCount'],
        'unhappyCount': json['unhappyCount'],
        'pilots': (json['pilots'].map(Person_1.PersonFromJSON)),
        'jumpers': (json['jumpers'].map(Person_1.PersonFromJSON)),
        'sun': (0, DaySun_1.DaySunFromJSON)(json['sun']),
    };
}
exports.DayFromJSONTyped = DayFromJSONTyped;
function DayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'date': value.date,
        'notes': value.notes,
        'canceled': value.canceled,
        'skydiverCount': value.skydiverCount,
        'studentCount': value.studentCount,
        'pilotCount': value.pilotCount,
        'happyCount': value.happyCount,
        'unhappyCount': value.unhappyCount,
        'pilots': (value.pilots.map(Person_1.PersonToJSON)),
        'jumpers': (value.jumpers.map(Person_1.PersonToJSON)),
        'sun': (0, DaySun_1.DaySunToJSON)(value.sun),
    };
}
exports.DayToJSON = DayToJSON;
