import { readonly, ref } from "vue"
import { ShowToastPayload, useStore } from "~/src/store"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function submitForm<T>(onSubmit: () => Promise<T>) {
  const store = useStore()
  const disabled = ref(false)
  
  const submit = async () => {
    try {
      disabled.value = true
      // must await here before returning, otherwise disabled is set to false too early
      return await onSubmit()
    } catch (e) {
      store.dispatch(new ShowToastPayload(
        'toast.unexpectedError',
        [],
        store.state.config.saveToastDuration
      ))
      return Promise.reject(e)
    } finally {
      disabled.value = false
    }
  }
  
  return {
    submit,
    submitDisabled: readonly(disabled)
  }
}