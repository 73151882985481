<template>
  <input type="submit" class="btn btn-primary" :class="btnClass" :value="t('save.label')" :disabled="disabled">
</template>

<script lang="ts">
import { computed, defineComponent, toRef } from 'vue'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { t } = useTranslation()
    const disabled = toRef(props, 'disabled')
    return {
      t,
      btnClass: computed(() => disabled.value ? { disabled: true } : {})
    }
  }
})
</script>

<style lang="scss" scoped>
input[type=submit] {
  min-width: 6em;
}
</style>
