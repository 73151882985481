<template>
  <icon name="cessna" :scale="1.75" :style="{ color }" data-cy="cessna-icon" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    color: String
  }
})
</script>
