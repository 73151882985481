<template>
  <div
    v-if="status === Status.READY && day != undefined"
    class="d-flex flex-column scroll-y day flex-fill"
    @touchmove="touchMoveHandler"
    @touchstart="touchStartHandler"
    @touchend="touchEndHandler"
  >
    <day-notes :day="day" class="pt-2 pr-3 pb-2 pl-3" />
    <main class="d-flex flex-column">
      <person-list :personArray="day.pilots" :date="date" :empty="t('day.noPilot')" />
      <person-list :personArray="day.jumpers" :date="date" :empty="t('day.noJumpers')" />
      <div class="button-placeholder d-flex"><span>&nbsp;</span></div>
    </main>
    <footer class="d-flex p-3 align-items-center justify-content-end">
      <add-person-button
        :to="newLink('student')">
        {{ t('student.addOrEditLabel') }} +
      </add-person-button>
      <add-person-button
        class="ml-3"
        :to="newLink('skydiver')">
        {{ t('skydiver.addOrEditLabel') }} +
      </add-person-button>
    </footer>
  </div>
  <div v-else-if="status === Status.LOADING" class="d-flex justify-content-center align-items-center flex-fill scroll-y">
    <Spinner />
  </div>
  <div v-else class="d-flex justify-content-center align-items-center flex-fill flex-column">
    <p>{{ t('error.unexpected') }}</p>
    <code>{{ errorMessage }}</code>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/var.scss';

.day {
  touch-action: pan-x pan-y;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $app-max-width;
  z-index: 1000;
}

.button-placeholder {
  height: 4em;
}
</style>

<script lang="ts">
import type { Ref } from 'vue'
import { defineComponent, toRef } from 'vue'
import AddPersonButton from '~/src/components/AddPersonButton.vue'
import DayNotes from '~/src/components/DayNotes.vue'
import Spinner from '~/src/components/Spinner.vue'
import PersonList from '~/src/components/PersonList.vue'
import { loadDayEffect, useDay, Status } from '~/src/composables/useDay'
import { useRouter } from 'vue-router'
import { modifyDate } from '~/src/util'
import { createThrottleer } from '~/src/util'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  setup(props) {
    const date = toRef(props, 'date')
    const { errorMessage, status } = loadDayEffect(date)
    const { t } = useTranslation()
    
    return {
      t,
      day: useDay(date),
      errorMessage,
      status,
      Status,
      ...setupTouchEvents(date)
    }
  },
  props: {
    date: {
      type: String,
      required: true
    }
  },
  methods: {
    newLink(type: string) {
      return {
        name: type,
        params: { date: this.date, id: 'new' }
      }
    }
  },
  components: {
    AddPersonButton,
    PersonList,
    DayNotes,
    Spinner
  }
})

function setupTouchEvents(date: Ref<string>) {
  const router = useRouter()
  const throttleer = createThrottleer(200)
  const data: [number, number][] = []

  const clearData = () => data.splice(0, data.length)
  const pushXY = (e: TouchEvent) => data.push([e.touches[0].clientX, e.touches[0].clientY])
  const diffXY = () => {
    if (data.length < 2) return [0, 0]
    
    return [
      data[0][0] - data[data.length - 1][0],
      data[0][1] - data[data.length - 1][1]
    ]
  }

  const touchEndHandler = () => {
    const [x, y] = diffXY()
    
    // Don't allow too much vertical movement (clear swipe left or right)
    if (Math.abs(y) < 50 && Math.abs(x) > 70) {
      router.push({
        name: "date",
        params: { date: modifyDate(date.value, Math.sign(x)) }
      })
    }

    clearData()
  }

  return {
    touchStartHandler: (e: TouchEvent) => {
      clearData()
      pushXY(e)
      throttleer.throttle(touchEndHandler)
    },
    touchMoveHandler: (e: TouchEvent) => {
      // don't push new values if throttleer has already triggered and cleared array
      if (data.length > 0) pushXY(e)
    },
    touchEndHandler: () => {
      touchEndHandler()
      throttleer.reset()
    }
  }
}
</script>
