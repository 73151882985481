<template>
  <add-or-update-person-form :title="t('support.label')"
    :id="id"
    :date="date"
    :person="support"
    :description="t('support.instructions')">
    <label>{{ t('roles.label') }}</label>
    <person-flag-selector :label="t('radio.label')" v-model="support.flags.instructorRadio" />
  </add-or-update-person-form>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import AddOrUpdatePersonForm from '~/src/components/AddOrUpdatePersonForm.vue'
import PersonFlagSelector from '~/src/components/PersonFlagSelector.vue'
import { PersonRoleEnum } from 'hto-api-client'
import { usePerson, useStubPerson } from '~/src/composables/usePerson'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  setup(props) {
    const { date, id } = toRefs(props)
    
    return {
      support: useStubPerson(usePerson(date, id), PersonRoleEnum.Support)
    }
  },
  data() {
    const { t } = useTranslation()
    return { t }
  },
  props: {
    id: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    }
  },
  components: {
    AddOrUpdatePersonForm,
    PersonFlagSelector
  }
})
</script>
