<template>
  <div class="d-flex align-items-center">
    <icon name="sun" class="mr-2" :scale="1.25" :style="{ color: '#feb125' }" />
    <span>{{ timeFormatting(sunset) }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { timeFormatting } from '~/src/util'

export default defineComponent({
  props: {
    sunset: {
      type: String,
      required: true
    }
  },
  methods: {
    timeFormatting
  }
})
</script>
