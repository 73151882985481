import type { HtoConfiguration } from '~/src/store'
import logo from '~/src/assets/hlu-logo.svg'
import { StudentStageEnum } from 'hto-api-client'

export const HLUConfiguration = Object.freeze<HtoConfiguration>({
  logo,
  logoAlt: 'Skydive Häme',
  title: 'HLU',
  clubColor: '#9fc9e3',
  clubName: 'hlu',
  studentColor: '#ffd124',
  studentStages: [
    StudentStageEnum.Sl,
    StudentStageEnum.Basic,
    StudentStageEnum.Tandem
  ],
  skydiverRoles: [
    "instructorAff",
    "instructorSl",
    "instructorTandem",
    "instructorCoach",
    "instructorRadio",
    "tandemVideo",
  ]
})