<template>
  <main class="d-flex flex-column scroll-y" @scroll.passive="throttledScrollHandler">
    <day-list-item v-for="dayState in days"
      :key="dayState.date"
      :day="dayState"
      :color="color(dayState)"
      :data-date="dayState.date" />
    <infinite-loading @infinite="infiniteHandler" :scrollTop="scrollTop" />
  </main>
</template>

<style lang="scss" scoped>
main { flex: 1 1 0%; }
</style>

<script lang="ts">
import { computed, defineComponent, ref, nextTick } from 'vue'
import { LoadTimelinePayload, SetScrollPositionMutation, useStore } from '~/src/store'
import { createThrottleer, modifyDate } from '~/src/util'
import DayListItem from '~/src/components/DayListItem.vue'
import type { StateChanger } from '~/src/components/InfiniteLoading.vue'
import InfiniteLoading from '~/src/components/InfiniteLoading.vue'
import type { Day } from 'hto-api-client'

export default defineComponent({
  name: 'infinite-day-list',
  setup() {
    const { state } = useStore()
    return {
      /** Undefined when app is loaded for the first time. */
      scrollTop: computed(() => state.scrollTop),
      /** Tracks what should be the start date for next timeline load. */
      since: computed(() => state.days.length === 0 ? state.today : state.days[state.days.length - 1].date),
      days: computed(() => state.days),
      isFirstLoad: ref(true),
      throttleer: createThrottleer(50)
    }
  },
  mounted() {
    // restore previous scroll position if we have one
    if (this.scrollTop !== undefined) {
      this.$el.scrollTop = this.scrollTop
    }
  },
  beforeUnmount() {
    this.throttleer.reset()
    this.scrollHandler()
  },
  methods: {
    throttledScrollHandler() {
      if (this.scrollTop === undefined) {
        this.scrollHandler()
      } else {
        this.throttleer.throttle(this.scrollHandler)
      }
    },
    scrollHandler() {
      // scrollTop = 0 if there's no scrollbar (i.e. when only one <day> is drawn)
      // If there's no scrollbar it means we are still loading more days, and 0 is not actual scrollbar position
      const hasVerticalScrollbar = this.$el.scrollHeight > this.$el.clientHeight
      if (hasVerticalScrollbar) {
        this.$store.commit(new SetScrollPositionMutation(this.$el.scrollTop))
      }
    },
    async infiniteHandler(state: StateChanger) {
      try {
        const date = modifyDate(this.since, this.isFirstLoad ? 0 : 1)
        const count = 30
        // Load days before `date` so the user can see the previous week
        const countBack = this.isFirstLoad ? 7 : undefined
        await this.$store.dispatch(new LoadTimelinePayload(date, count, countBack))
        this.isFirstLoad = false
        if (this.scrollTop === undefined) {
          // Scroll past `countBack` dates, but only if we have no previous scroll position.
          // If we do, no need to alter scroll position - just loading more days
          await nextTick()
          this.scrollTo(date)
        }
        state.loaded()
      } catch (e) {
        state.error(e instanceof Error ? e.message : 'Tuntematon virhe')
      }
    },
    scrollTo(date: string) {
      // The distance of the <day> element relative to the top of <main>
      // Uncaught (in promise) TypeError: Cannot destructure property 'offsetTop' of 'this.$el.querySelector(...)' as it is null.
      const { offsetTop } = this.$el.querySelector(`[data-date="${date}"]`)
      this.$el.scrollTop = offsetTop // triggers scrollHandler
      // When app is opened to a tab that is hidden (mouse middle click), scrollTop prop will
      // stay undefined because scoll event is not triggered unless tab is visible.
      // Here we trigger it manually to stop infinite scrolling until tab is activated.
      if (document.hidden) {
        this.scrollHandler()
      }
    },
    color(day: Day) {
      if (day.date == this.$store.state.today) {
        return "#e0e0e0"
      }
      return undefined
    }
  },
  components: {
    DayListItem,
    InfiniteLoading
  }
})
</script>
