import type { ActionTree } from 'vuex'
import type { DefaultApi, HTTPRequestInit, TranslationKey, } from 'hto-api-client'
import { ResponseError } from 'hto-api-client'
import type {
  RootState,
  LoadTimelinePayload,
  UpdateDayPayload,
  DeletePersonPayload} from '~/src/store/types'
import {
  LoadDayPayload,
  AddPersonPayload,
  SetDayMutation,
  ShowToastPayload
} from '~/src/store/types'
import { findPerson } from '~/src/store/getters'

async function catchApiError<T>(action: Promise<T>): Promise<T> {
  try {
    return await action
  } catch (error) {
    if (error instanceof ResponseError) {
      throw new Error(await error.response.text())
    }

    throw error
  }
}

function initOverride(state: RootState) {
  return async function setAcceptLanguage({ init }: { init: HTTPRequestInit }): Promise<RequestInit> {
    init.headers = init.headers ?? {}
    init.headers["accept-language"] = state.lang
    return init
  }
}

export function actions(api: DefaultApi): ActionTree<RootState, RootState> {
  return {
    async loadTimeline({ commit, state }, payload: LoadTimelinePayload): Promise<void> {
      const days = await catchApiError(api.timelineGet(state.config.clubName, payload.since, payload.count, payload.countBack, initOverride(state)))
      commit(new SetDayMutation(days))
    },
    async loadDay({ commit, state }, payload: LoadDayPayload): Promise<void> {
      const day = await catchApiError(api.timelineDayGet(payload.date, state.config.clubName, initOverride(state)))
      commit(new SetDayMutation(day))
    },
    async updateDay({ state, dispatch }, payload: UpdateDayPayload): Promise<void> {
      await catchApiError(api.timelineDayPut(payload.date, state.config.clubName, payload.update, initOverride(state)))
      dispatch(new ShowToastPayload("toast.dayEditSuccess", [], state.config.saveToastDuration))
    },
    async addPerson({ state, dispatch }, payload: AddPersonPayload): Promise<void> {
      await catchApiError(api.addOrUpdatePerson(payload.date, state.config.clubName, payload.person, initOverride(state)))
      if (payload.showToast) {
        const message: TranslationKey = payload.person.id ? "toast.personSavedSuccess" : "toast.personAddedSuccess"
        dispatch(new ShowToastPayload(message, [], state.config.saveToastDuration))
      }
    },
    async deletePerson({ state, dispatch }, payload: DeletePersonPayload): Promise<void> {
      const person = findPerson(state)(payload.date, payload.id)
      if (person == undefined) return
      await catchApiError(api.deletePerson(payload.date, payload.id, state.config.clubName, initOverride(state)))
      
      delete person.id // To make api.addOrUpdatePerson() create a new person in case of undo
      dispatch(new ShowToastPayload(
        "toast.attendanceCancelled",
        [
          new AddPersonPayload(payload.date, person, 'noToast'),
          new LoadDayPayload(payload.date)
        ],
        state.config.deleteToastDuration
      ))
    },
    // eslint-disable-next-line
    showToast({}, _: ShowToastPayload) { /* no-op */ }
  }
}
