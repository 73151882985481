import type { HtoConfiguration } from '~/src/store'
import logo from '~/src/assets/skydivekarjala-logo.svg'
import { StudentStageEnum } from 'hto-api-client'

export const KarjalaConfiguration = Object.freeze<HtoConfiguration>({
  logo,
  logoAlt: 'Skydive Karjala',
  title: 'Skydive Karjala',
  clubColor: '#a90000',
  clubName: 'karjala',
  studentColor: '#f28d17',
  studentStages: [
    StudentStageEnum.Aff13,
    StudentStageEnum.Aff47,
    StudentStageEnum.Sl,
    StudentStageEnum.Basic,
    StudentStageEnum.Tandem,
  ],
  skydiverRoles: [
    "instructorAff",
    "instructorSl",
    "instructorTandem",
    "instructorCoach",
    "instructorRadio",
    "tandemVideo",
  ]
})
