import type { Language } from "hto-api-client"

function formatIsoDate(value: string, locales: Intl.LocalesArgument, options: Intl.DateTimeFormatOptions): string {
  return new Date(value).toLocaleDateString(locales, options)
}

export function fullDayFormatting(value: string): string {
  return formatIsoDate(value, 'fi-FI', { day: 'numeric', month: 'numeric', year: 'numeric' })
}

export function dayFormatting(value: string): string {
  return formatIsoDate(value, 'fi-FI', { day: 'numeric', month: 'numeric' })
}

export function yearFormatting(value: string): string {
  return formatIsoDate(value, 'fi-FI', { year: 'numeric' })
}

export function weekdayFormatting(value: string, lang: Language, mode?: 'short' | 'long'): string {
  return formatIsoDate(value, langToLocale[lang], { weekday: mode ? mode : 'short' })
}

const langToLocale: Record<Language, Intl.LocalesArgument> = {
  "fi": "fi-FI",
  "en": "en-US"
}

export function capitalize(value: string): string {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
}

export function timeFormatting(value: string): string {
  const date = new Date(value)
  return isNaN(date.getTime()) ? '-' : date.toLocaleTimeString('fi-FI', {
    hour: '2-digit',
    minute: '2-digit'
  })
}

export function modifyDate(date: string, diff: number): string {
  const start = new Date(date)
  start.setUTCDate(start.getUTCDate() + diff)
  return start.toISOString().split('T', 1)[0]
}

export function fst<T>(a: T | T[]): T {
  return Array.isArray(a) ? a[0] : a
}

interface Throttleer {
  throttle: (fn: () => void) => void;
  reset: () => void;
}

export function createThrottleer(sleep: number): Throttleer {
  const timers: number[] = []
  let caches: (() => void)[] = []
  return {
    throttle(fn: () => void) {
      if (caches.indexOf(fn) === -1) {
        // cache current handler
        caches.push(fn)

        // save timer for current handler
        timers.push(window.setTimeout(() => {
          fn()

          // empty cache and timer
          caches.splice(caches.indexOf(fn), 1)
          timers.shift()
        }, sleep))
      }
    },
    reset() {
      // reset all timers
      timers.forEach((timer) => {
        clearTimeout(timer)
      })
      timers.length = 0
      caches = []
    }
  }
}
