<template>
  <router-link v-if="action.kind === 'link'"
    class="nav-btn"
    :title="t(action.titleKey)"
    :data-cy="action.icon"
    :to="action.onClick"
  >
    <icon :name="action.icon" />
  </router-link>
  <button v-else
    class="nav-btn"
    :title="t(action.titleKey)"
    :data-cy="action.icon"
    type="button"
    @click="activateNavigationAction()"
  >
    <icon :name="action.icon" />
  </button>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { useStore } from '~/src/store'
import type { NavigationAction } from '~/src/navbar/actions'
import { useRouter } from 'vue-router'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  props: {
    action: {
      type: Object as PropType<NavigationAction>,
      required: true
    }
  },
  setup(props) {
    const { t } = useTranslation()
    const { dispatch } = useStore()
    const { push, back } = useRouter()
    return {
      t,
      activateNavigationAction: async () => {
        if (props.action.kind !== 'action') return
        for (const item of props.action.onClick) {
          if (typeof item !== 'string' && 'type' in item) {
            await dispatch(item)
          } else if (item === '__back') {
            await back()
          } else {
            await push(item)
          }
        }
      }
    }
  }
})
</script>
