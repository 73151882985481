<template>
  <div class="p-3 d-flex flex-column" style="flex: 1">
    <h2>{{ title }}</h2>
    <p v-if="description" v-html="description"></p>
    <form v-if="loadStatus === Status.READY" @submit.prevent="submit" class="pt-2 d-flex flex-column flex-fill">
      <name-form-control v-model="mutablePerson.name" />
      <slot></slot>
      <ready-picker-form-control v-model="mutablePerson.readyTime" />
      <notes-form-control v-model:text="mutablePerson.notes" v-model:unhappy="mutablePerson.flags.unhappy" :label="t('notes.label')" />
      <form-save-button class="align-self-end" :disabled="submitDisabled" />
    </form>
    <div v-else-if="loadStatus === Status.LOADING" class="d-flex justify-content-center align-items-center flex-fill">
      <Spinner />
    </div>
    <div v-else class="d-flex flex-column justify-content-center align-items-center flex-fill">
      <p>{{ t('error.unexpected') }}</p>
      <code>{{ errorMessage }}</code>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType} from 'vue'
import { defineComponent, toRefs } from 'vue'
import ReadyPickerFormControl from '~/src/components/ReadyPickerFormControl.vue'
import NotesFormControl from '~/src/components/NotesFormControl.vue'
import NameFormControl from '~/src/components/NameFormControl.vue'
import FormSaveButton from '~/src/components/FormSaveButton.vue'
import Spinner from '~/src/components/Spinner.vue'
import { AddPersonPayload, LoadDayPayload, useStore } from '~/src/store'
import type { Person } from 'hto-api-client'
import { useRouter } from 'vue-router'
import { submitForm } from '~/src/composables/submitForm'
import { loadDayEffect, Status } from '~/src/composables/useDay'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  props: {
    date: { required: true, type: String },
    title: { required: true, type: String },
    description: { required: false, type: String },
    person: { required: true, type: Object as PropType<Person> }
  },
  setup(props) {
    const { t } = useTranslation()
    const { dispatch } = useStore()
    const router = useRouter()
    const { person, date } = toRefs(props)

    const { status: loadStatus, errorMessage } = loadDayEffect(date)

    const { submitDisabled, submit } = submitForm(async () => {
      await dispatch(new AddPersonPayload(date.value, person.value, 'showToast'))
      await dispatch(new LoadDayPayload(date.value))
      await router.replace({
        name: 'date',
        params: { date: date.value }
      })      
    })

    return {
      t,
      mutablePerson: person,
      submitDisabled,
      submit,
      Status,
      loadStatus,
      errorMessage
    }
  },
  components: {
    ReadyPickerFormControl,
    NotesFormControl,
    NameFormControl,
    FormSaveButton,
    Spinner
  }
})
</script>
