<template>
  <teleport to="body">
    <router-link :to="previousDate" :title="t('nav.previousDay')" data-cy="nav-previous-date" class="d-lg-flex left">
      <icon name="arrow-left" />
    </router-link>
    <router-link :to="nextDate" :title="t('nav.nextDay')" data-cy="nav-next-date" class="d-lg-flex right">
      <icon name="arrow-right" />
    </router-link>
  </teleport>
</template>

<style lang="scss" scoped>
$radius: 1000em;
$border-color: #e4e5e6;

a {
  position: fixed;
  display: none; /* changes to flex on lg breakpoint */
  top: 32%;
  color: #777;
  padding: 4rem 2rem;
  &.left {
    left: 0;
    border-radius: 0 $radius $radius 0;
    border-right: 1px solid $border-color;
  }
  &.right {
    right: 0;
    border-radius: $radius 0 0 $radius;
    border-left: 1px solid $border-color;
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { modifyDate, fst } from '~/src/util'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  name: 'prev-next-navigation',
  setup() {
    const { t } = useTranslation()
    const { params, name } = toRefs(useRoute())
    const dateLocation = (diff: number) => computed(() => ({
      name: name.value ?? "root",
      params: { date: modifyDate(fst(params.value.date), diff) }
    }))
    return {
      t,
      nextDate: dateLocation(+1),
      previousDate: dateLocation(-1)
    }
  }
})
</script>
