<template>
  <add-or-update-person-form :title="t('pilot.label')"
    :id="id"
    :date="date"
    :person="pilot" />
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import AddOrUpdatePersonForm from '~/src/components/AddOrUpdatePersonForm.vue'
import { PersonRoleEnum } from 'hto-api-client'
import { usePerson, useStubPerson } from '~/src/composables/usePerson'
import { useTranslation } from '~/src/composables/useTranslation'

export default defineComponent({
  setup(props) {
    const { t } = useTranslation()
    const { date, id } = toRefs(props)
    
    return {
      t,
      pilot: useStubPerson(usePerson(date, id), PersonRoleEnum.Pilot)
    }
  },
  props: {
    id: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    }
  },
  components: {
    AddOrUpdatePersonForm
  }
})
</script>
