"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DaySunToJSON = exports.DaySunFromJSONTyped = exports.DaySunFromJSON = exports.instanceOfDaySun = void 0;
/**
 * Check if a given object implements the DaySun interface.
 */
function instanceOfDaySun(value) {
    let isInstance = true;
    isInstance = isInstance && "sunrise" in value;
    isInstance = isInstance && "sunset" in value;
    return isInstance;
}
exports.instanceOfDaySun = instanceOfDaySun;
function DaySunFromJSON(json) {
    return DaySunFromJSONTyped(json, false);
}
exports.DaySunFromJSON = DaySunFromJSON;
function DaySunFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sunrise': json['sunrise'],
        'sunset': json['sunset'],
    };
}
exports.DaySunFromJSONTyped = DaySunFromJSONTyped;
function DaySunToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sunrise': value.sunrise,
        'sunset': value.sunset,
    };
}
exports.DaySunToJSON = DaySunToJSON;
