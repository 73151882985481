import type { DefaultApi } from "hto-api-client"
import type { Store } from 'vuex'
import { createStore as createStoreBase, useStore as baseUseStore } from 'vuex'
import type { RootState } from "~/src/store/types"
import { actions } from '~/src/store/actions'
import { mutations } from '~/src/store/mutations'
import { getConfig } from '~/src/store/config'
import type { InjectionKey } from "vue"

export type {
  RootState,
  HtoConfiguration,
  NotStudent,
  ValidStudentStage,
  ValidSkydiverRole
} from '~/src/store/types'

export {
  LoadTimelinePayload,
  LoadDayPayload,
  UpdateDayPayload,
  AddPersonPayload,
  DeletePersonPayload,
  ShowToastPayload,
  SetDayMutation,
  SetScrollPositionMutation,
  SetLanguageMutation,
  StubPerson,
} from '~/src/store/types'

export function createStore(config: ConstConfiguration, api: DefaultApi): [Store<RootState>, InjectionKey<Store<RootState>>] {
  const store = createStoreBase<RootState>({
    state() {
      return {
        today: new Date().toISOString().split('T', 1)[0],
        days: [],
        scrollTop: undefined,
        config: getConfig(config),
        lang: 'fi'
      }
    },
    actions: actions(api),
    mutations,
    strict: true
  })

  return [store, key]
}

const key: InjectionKey<Store<RootState>> = Symbol()

export function useStore(): Store<RootState> {
  return baseUseStore(key)
}