<template>
  <label for="name">
    <slot></slot> <span v-if="required" style="color: red">*</span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    required: {
      required: false,
      type: Boolean,
      default: false
    }
  },
})
</script>
