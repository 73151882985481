import type { NotStudent, ValidStudentStage } from "~/src/store"
import { StubPerson, useStore } from "~/src/store"
import { findPerson } from "~/src/store/getters"
import type { Person, PersonRoleEnum} from "hto-api-client"
import { StudentStageEnum } from "hto-api-client"
import type { Ref} from "vue"
import { computed, ref, watchEffect } from "vue"

/** id = 'new' when adding a new person */
export function usePerson(date: Ref<string>, id: Ref<string>): Ref<Person | undefined> {
  const { state } = useStore()
  return computed(() => id.value == 'new' ? undefined : findPerson(state)(date.value, id.value))
}

export function useStubPerson(person: Ref<Person | undefined>, role: 'student', stage: ValidStudentStage): Ref<Person>
export function useStubPerson(person: Ref<Person | undefined>, role: NotStudent): Ref<Person>
export function useStubPerson(person: Ref<Person | undefined>, role: PersonRoleEnum, stage?: StudentStageEnum): Ref<Person> {
  const stub = ref(new StubPerson(role, stage ?? StudentStageEnum.None))
  watchEffect(() => {
    const r = person.value
    if (r != undefined) {
      stub.value = {
        id: r.id,
        role: r.role,
        studentStage: r.studentStage,
        name: r.name,
        notes: r.notes,
        readyTime: r.readyTime,
        flags: { ...r.flags },
      }
    }
  })
  return stub
}
