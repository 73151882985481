<template>
  <router-link
    class="person d-flex align-items-center pt-2 pb-2"
    :class="personClass"
    :to="editLink"
  >
    <div class="icon ml-3 mr-3">
      <component :is="icon" />
    </div>
    <div class="d-flex flex-column data">
      <span class="name">{{ person.name }}</span>
      <span v-if="happyNotes" class="note note-happy">{{ happyNotes }}</span>
      <span v-if="person.unhappyNotes" class="note note-unhappy">{{ capitalize(person.unhappyNotes) }}</span>
      <div class="note" :class="noteClass">
        <span v-if="person.notes" class="mr-2">{{ person.notes }}</span>
        <span v-else-if="person.flags.unhappy" class="mr-2">-</span>
      </div>
    </div>
    <time class="ml-auto mr-3 text-right">{{ person.readyTime }}</time>
  </router-link>
</template>

<script lang="ts">
import type { PropType, Component } from 'vue'
import { defineComponent } from 'vue'
import { Person, StudentStageEnum, TranslationKey } from 'hto-api-client'
import { PersonRoleEnum } from 'hto-api-client'
import type { RouteLocationRaw } from 'vue-router'
import { capitalize } from '~/src/util'
import IconCessna from '~/src/components/IconCessna.vue'
import IconStudent from '~/src/components/IconStudent.vue'
import IconInstructor from '~/src/components/IconInstructor.vue'
import IconSkydiver from '~/src/components/IconSkydiver.vue'
import IconSupport from './IconSupport.vue'
import { useTranslation } from '~/src/composables/useTranslation'

const translationKeyMap: Record<StudentStageEnum, TranslationKey> = {
  aff1_3: 'student.stageAff13',
  aff4_7: 'student.stageAff47',
  sl: 'student.stageSl',
  basic: 'student.stageBasic',
  tandem: 'student.stageTandem',
  none: 'undefined'
}

export default defineComponent({
  props: {
    date: {
      required: true,
      type: String
    },
    person: {
      required: true,
      type: Object as PropType<Person>
    }
  },
  setup() {
    const { t } = useTranslation()

    return { t }
  },
  computed: {
    happyNotes(): string {
      const text = []
      if (this.maybeStudentStage) text.push(this.maybeStudentStage)
      if (this.person.happyNotes) text.push(this.person.happyNotes)
      return text.join(' | ')
    },
    isInstructor(): boolean {
      return (
        this.person.flags.instructorAff ||
        this.person.flags.instructorCoach ||
        this.person.flags.instructorSl ||
        this.person.flags.instructorRadio ||
        this.person.flags.instructorTandem
      ) && this.person.role === PersonRoleEnum.Skydiver
    },
    maybeStudentStage(): string {
      return this.t(translationKeyMap[this.person.studentStage])
    },
    personClass(): Record<string, boolean> {
      const css: Record<string, boolean> = { 'shadow':  this.editLink != '' }
      if (this.person.role === PersonRoleEnum.Support) {
        css['order-1'] = true
      } else if (this.isInstructor) {
        css['order-2'] = true
      } else if (this.person.role === PersonRoleEnum.Student) {
        css['order-3'] = true
      } else if (this.person.role === PersonRoleEnum.Skydiver) {
        css['order-4'] = true
      } else {
        css['order-5'] = true
      }
      return css
    },
    editLink(): RouteLocationRaw | '' {
      if (!this.person.id) return ''
      return {
        name: this.person.role.toString(),
        params: {
          id: this.person.id,
          date: this.date
        }
      }
    },
    noteClass(): Record<string, boolean> {
      return {
        'note-unhappy': this.person.flags.unhappy
      }
    },
    icon(): Component {
      if (this.person.role === PersonRoleEnum.Pilot) return IconCessna
      else if (this.person.role === PersonRoleEnum.Student) return IconStudent
      else if (this.isInstructor) return IconInstructor
      else if (this.person.role === PersonRoleEnum.Skydiver) return IconSkydiver
      else if (this.person.role === PersonRoleEnum.Support) return IconSupport
      else throw new Error('No icon found')
    }
  },
  methods: {
    capitalize
  },
  components: {
    IconCessna,
    IconStudent,
    IconInstructor,
    IconSkydiver,
    IconSupport
  }
})
</script>

<style lang="scss" scoped>
$note-font-size: 0.875rem;

.person {
  font-size: 1em;

  .icon {
    line-height: 32px;
    width: 32px;
    height: 32px;
    color: black;
    text-align: center;
  }

  .data {
    flex: 3;
  }

  time {
    flex: 1;
    font-size: $note-font-size;
  }

  .name, time {
    color: #444;
  }

  .note {
    color: #888;
    font-size: $note-font-size;
  }

  .note-unhappy {
    color: red;
  }

  &:hover {
    text-decoration: none;
    cursor: default;
    .note {
      color: #444;
    }
  }

  &.shadow:hover {
    box-shadow: 0 1px 2px 0 rgba(132, 137, 141, 0.3),
                0 1px 3px 1px rgba(60,64,67,.15);
    z-index: 1;
    cursor: pointer;
  }
}

@for $i from 0 through 10 {
  .order-#{$i} { order: $i; }
}
</style>
