<template>
  <router-link class="btn" :to="to" :data-cy="name">
    <slot></slot>
  </router-link>
</template>

<style scoped>
.btn {
  box-shadow: 0 6px 6px -6px #ccc;
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

type NamedRoute = Extract<RouteLocationRaw, { name?: string | symbol }>

export default defineComponent({
  props: {
    to: {
      required: true,
      type: Object as PropType<NamedRoute>
    }
  },
  setup(props) {
    return { name: props.to.name?.toString() }
  }
})
</script>
