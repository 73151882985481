<template>
  <div class="p-3">
    <section>
      <h2>{{ t('help.title') }}</h2>
      <div v-html="t('help.textHtml')"></div>
    </section>
    <section class="mt-3">
      <h3>{{ t('help.iconDescriptions') }}</h3>
      <person-component date="0000-01-01" :person="pilot" />
      <person-component date="0000-01-01" :person="instructor" />
      <person-component date="0000-01-01" :person="student" />
      <person-component date="0000-01-01" :person="skydiver" />
      <person-component date="0000-01-01" :person="support" />
    </section>
    <footer class="text-center pt-4 text-small m-3">
      <label for="language">{{ t('help.languageLabel') }}</label>
      <select class="form-control mb-3" id="language" v-model="language" data-cy="language">
        <option key="fi" value="fi">Suomi</option>
        <option key="en" value="en">English</option>
      </select>
      <div v-html="t('help.footerHtml')"></div>
    </footer>
  </div>
</template>

<style scoped>
footer {
  border-top: 1px solid lightgray;
}
</style>

<script lang="ts">
import PersonComponent from '~/src/components/Person.vue'
import type { Person, Flags, Language } from 'hto-api-client'
import { PersonRoleEnum, StudentStageEnum } from 'hto-api-client'
import { defineComponent } from 'vue'
import { useTranslation } from '~/src/composables/useTranslation'
import { SetLanguageMutation } from '../store'

const flags: Flags = {
  instructorAff: false,
  instructorCoach: false,
  instructorRadio: false,
  instructorSl: false,
  instructorTandem: false,
  studentRadio: false,
  tandemVideo: false,
  unhappy: false
}

type PersonBuilder = (name: string) => Person

const pilot: PersonBuilder = (name) => ({
  name,
  role: PersonRoleEnum.Pilot,
  readyTime: '',
  studentStage: StudentStageEnum.None,
  notes: '',
  flags
})

const student: PersonBuilder = (name) => ({
  name,
  role: PersonRoleEnum.Student,
  readyTime: '',
  studentStage: StudentStageEnum.None,
  notes: '',
  flags
})

const skydiver: PersonBuilder = (name) => ({
  name,
  role: PersonRoleEnum.Skydiver,
  readyTime: '',
  studentStage: StudentStageEnum.None,
  notes: '',
  flags
})

const instructor: PersonBuilder = (name) => ({
  name,
  role: PersonRoleEnum.Skydiver,
  readyTime: '',
  studentStage: StudentStageEnum.None,
  notes: '',
  flags: {
    instructorAff: false,
    instructorCoach: false,
    instructorRadio: false,
    instructorSl: true,
    instructorTandem: false,
    studentRadio: false,
    tandemVideo: false,
    unhappy: false
  }
})

const support: PersonBuilder = (name) => ({
  name,
  role: PersonRoleEnum.Support,
  readyTime: '',
  studentStage: StudentStageEnum.None,
  notes: '',
  flags: {
    instructorAff: false,
    instructorCoach: false,
    instructorRadio: false,
    instructorSl: true,
    instructorTandem: false,
    studentRadio: false,
    tandemVideo: false,
    unhappy: false
  }
})

export default defineComponent({
  data: () => {
    const { t } = useTranslation()
    return {
      t,
      pilot: pilot(t("pilot.label")),
      student: student(t('student.label')),
      skydiver: skydiver(t('skydiver.label')),
      instructor: instructor(t('instructor.label')),
      support: support(t('support.label')),
    }
  },
  computed: {
    language: {
      get () {
        return this.$store.state.lang
      },
      set (lang: Language) {
        this.$store.commit(new SetLanguageMutation(lang))
      }
    }
  },
  components: {
    PersonComponent
  }
})
</script>
