<template>
  <navbar>
    <template v-slot:banner>
      <date-info :date="date" />
    </template>
    <template v-slot:actions>
      <div class="d-flex nav-btn-group justify-content-end">
        <action-button
          v-for="action in actions"
          :key="action.icon"
          :action="action"
        />
      </div>
    </template>
  </navbar>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import Navbar from '~/src/navbar/Navbar.vue'
import DateInfo from '~/src/navbar/DateInfo.vue'
import ActionButton from '~/src/navbar/ActionButton.vue'
import type { NavigationAction } from '~/src/navbar/actions'

export default defineComponent({
  props: {
    date: {
      type: String,
      required: true
    },
    actions: {
      type: Array as PropType<NavigationAction[]>,
      required: true
    }
  },
  components: {
    Navbar,
    DateInfo,
    ActionButton
  }
})
</script>

<style lang="scss" scoped>
.nav-btn-group {
  flex-grow: 1;

  :deep(.nav-btn) {
    margin-left: 1.5rem;
    &:not(:last-child) {
      margin-left: 0;
    }
  }
}
</style>
