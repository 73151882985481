<template>
  <div v-if="personArray.length" class="d-flex flex-column m-0 p-0">
    <person-component
      v-for="person in personArray"
      :key="person.id"
      :date="date"
      :person="person"
    />
  </div>
  <div v-else class="empty">
    {{ empty }}
  </div>
</template>

<style lang="scss" scoped>
.empty {
  color: #666;
  padding: 0.5rem 1rem; // pt-2 pr-3 pb-2 pl-3
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { Person } from 'hto-api-client'
import PersonComponent from '~/src/components/Person.vue'

export default defineComponent({
  name: 'PersonList',
  props: {
    date: {
      required: true,
      type: String
    },
    personArray: {
      type: Array as PropType<Person[]>,
      required: true
    },
    empty: {
      type: String,
      required: true
    }
  },
  components: {
    PersonComponent
  }
})
</script>
