<template>
  <div :key="key" id="app" class="d-flex flex-column">
    <router-view name="nav" v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
    <router-view class="main" />
    <toaster />
  </div>
</template>

<style lang="scss" scoped>
@import './assets/var.scss';

#app {
  height: 100vh;
  max-width: $app-max-width;
  margin: 0 auto;
  min-width: 320px;
}

.main {
  background: white;
  border-radius: 12px;
  border-top: 1px solid #f9f8fa;
  position: relative; /* makes it offsetParent */
}
</style>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Toaster from '~/src/components/Toaster.vue'
import { useStore } from '~/src/store'

export default defineComponent({
  setup() {
    const { state } = useStore()
    document.title = `HTO | ${state.config.title}`

    // re-render app if language changes
    return { key: computed(() => state.lang) }
  },
  components: {
    Toaster
  }
})
</script>
