import type { HtoConfiguration } from '~/src/store/types'
import { HLUConfiguration } from '~/src/store/config/hlu'
import { TamLkConfiguration } from '~/src/store/config/tamlk'
import { SkydiveJklConfiguration } from '~/src/store/config/jyvaskyla'
import { SdfConfiguration } from '~/src/store/config/sdf'
import { KarjalaConfiguration } from '~/src/store/config/karjala'

const configs: Record<string, HtoConfiguration> = {
  'hto.skydivehame.com': HLUConfiguration,
  'hto.tamlk.org': TamLkConfiguration,
  'hto.skydivejkl.fi': SkydiveJklConfiguration,
  'hto.skydivefinland.fi': SdfConfiguration,
  'hto.skydivekarjala.fi': KarjalaConfiguration
}

export function getConfig(config: ConstConfiguration): ConstConfiguration & HtoConfiguration {
  let clubConfig = configs[config.hostname]

  if (clubConfig) {
    return {
      ...config,
      ...clubConfig,
    }
  }
  
  if (process.env.NODE_ENV !== 'production') {
    if (config.hostname.endsWith('-test')) {
      clubConfig = configs['hto.tamlk.org']
    } else {
      const options = Object.values(configs)
      clubConfig = options[Math.floor(Math.random() * options.length)]
    }
    
    return {
      ...config,
      ...clubConfig,
      clubName: config.hostname
    }
  }

  throw new Error(`No config for hostname: ${config.hostname}`)
}
