<template>
  <div class="p-3 d-flex flex-column" style="flex: 1">
    <form @submit.prevent="submit" class="pt-2 mb-2 d-flex flex-column">
      <notes-form-control v-model:text="notes.heading" v-model:unhappy="canceled" :label="t('day.titleLabel')" />
      <div class="form-group">
        <form-label for="additional-notes">{{ t("day.descriptionLabel") }}</form-label>
        <textarea rows="8" maxlength="1600" id="additional-notes" class="form-control" data-cy="additional-notes" v-model="notes.details"></textarea>
      </div>
      <div class="d-flex align-self-end">
        <copy-day-button @click="copyPreviousDay" />
        <form-save-button class="ml-3" :disabled="submitDisabled" />
      </div>
    </form>
    <div class="d-flex align-self-end mt-5">
      <add-person-button :to="addSupport">
        {{ t('support.label') }} +
      </add-person-button>
      <add-person-button :to="addPilot" class="ml-3">
        {{ t('pilot.label') }} +
      </add-person-button>
    </div>
  </div>
</template>

<script lang="ts">
import type { Ref} from 'vue'
import { computed, defineComponent, ref, toRef, watchEffect } from 'vue'
import NotesFormControl from '~/src/components/NotesFormControl.vue'
import CopyDayButton from '~/src/components/CopyDayButton.vue'
import FormLabel from '~/src/components/FormLabel.vue'
import AddPersonButton from '~/src/components/AddPersonButton.vue'
import FormSaveButton from '~/src/components/FormSaveButton.vue'
import { LoadDayPayload, UpdateDayPayload, useStore } from '~/src/store'
import { useDay, dayNotesLens, loadDayEffect } from '~/src/composables/useDay'
import { submitForm } from '~/src/composables/submitForm'
import { useTranslation } from '~/src/composables/useTranslation'
import { useRouter } from 'vue-router'
import { type Day } from 'hto-api-client'
import { modifyDate } from '~/src/util'
import { findDay } from '~/src/store/getters'

export default defineComponent({
  props: {
    date: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { t } = useTranslation()
    const { dispatch, state } = useStore()
    const router = useRouter()
    const date = toRef(props, 'date')

    loadDayEffect(date)

    const { notes, canceled } = mutableDay(useDay(date))
    const { submitDisabled, submit } = submitForm(async () => {
      let multiline = notes.value.heading
      if (notes.value.details.length > 0) {
        multiline += "\n" + notes.value.details
      }
      await dispatch(
        new UpdateDayPayload(date.value, {
          canceled: canceled.value,
          notes: multiline
        })
      )
      await dispatch(new LoadDayPayload(date.value))
      await router.push({ name: 'root' })
    })

    const fetchPreviousDay = async () => {
      const prevDate = modifyDate(date.value, -1)
      let day = findDay(state)(prevDate)
      if (day !== undefined) return day
      
      await dispatch(new LoadDayPayload(prevDate))
      day = findDay(state)(prevDate)
      if (day === undefined) throw new Error(`Latausvirhe ${prevDate}`)
      return day
    }

    const copyPreviousDay = async () => {
      const previousDay = await fetchPreviousDay()
      const prevDayNotes = dayNotesLens(previousDay)
      notes.value.heading = prevDayNotes.heading
      notes.value.details = prevDayNotes.details
      canceled.value = previousDay.canceled
    }

    return {
      t,
      notes,
      canceled,
      submit,
      submitDisabled,
      addPilot: computed(() => addPilotLink(date.value)),
      addSupport: computed(() => addSupportLink(date.value)),
      copyPreviousDay
    }
  },
  components: {
    NotesFormControl,
    AddPersonButton,
    FormSaveButton,
    FormLabel,
    CopyDayButton
  }
})

function mutableDay(day: Ref<Day | undefined>) {
  const notes = ref({
    heading: '',
    details: ''
  })
  const canceled = ref(false)

  watchEffect(() => {
    if (day.value !== undefined) {
      const { heading, details } = dayNotesLens(day.value)
      notes.value.heading = heading
      notes.value.details = details
      canceled.value = day.value.canceled
    }
  })

  return { notes, canceled }
}

function addPilotLink(date: string) {
  return {
    name: 'pilot',
    params: { date: date, id: 'new' }
  }
}
function addSupportLink(date: string) {
  return {
    name: 'support',
    params: { date: date, id: 'new' }
  }
}
</script>
