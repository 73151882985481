import type { Router, RouteRecordRaw, NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { createRouter as createRouterBase, createWebHistory } from 'vue-router'

import InfiniteDayList from  '~/src/views/InfiniteDayList.vue'
import Help from '~/src/views/Help.vue'
import Day from '~/src/views/Day.vue'
import FormDay from '~/src/views/FormDay.vue'
import FormSkydiver from '~/src/views/FormSkydiver.vue'
import FormStudent from '~/src/views/FormStudent.vue'
import FormPilot from '~/src/views/FormPilot.vue'
import FormSupport from './views/FormSupport.vue'
import DateNavbar from '~/src/navbar/DateNavbar.vue'
import LogoNavbar from '~/src/navbar/LogoNavbar.vue'
import Navbar from '~/src/navbar/Navbar.vue'
import { fst } from '~/src/util'
import type { NavigationAction} from '~/src/navbar/actions'
import { deletePerson, editDate, help } from '~/src/navbar/actions'

const history = createWebHistory()
const paramLens = (route: RouteLocationNormalized, key: string) => fst(route.params[key])

function defaultProps (route: RouteLocationNormalized) {
  return {
    date: paramLens(route, 'date'),
    id: paramLens(route, 'id'),
  }
}

interface NavProps {
  actions: NavigationAction[];
  previous: string;
  showPrevNext?: true;
}

function createProps(props: (route: RouteLocationNormalized) => NavProps) {
  return {
    nav: (route: RouteLocationNormalized) => ({
      ...defaultProps(route),
      ...props(route)
    }),
    default: defaultProps
  }
}

function personPageActions(route: RouteLocationNormalized) {
  const id = paramLens(route, 'id')
  if (id === 'new') {
    return [
      help()
    ]
  } else {
    return [
      help(),
      deletePerson(
        paramLens(route, 'date'),
        id
      )
    ]
  }
}

const validateDateParameter: NavigationGuard = (to) => {
  const IS_DATE = /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/
  const date = paramLens(to, 'date')
  if (!IS_DATE.test(date) || isNaN(Date.parse(date))) {
    return { name: 'root' }
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'root',
    components: {
      default: InfiniteDayList,
      nav: LogoNavbar
    }
  },
  {
    path: '/help',
    name: 'help',
    components: {
      default: Help,
      nav: Navbar
    },
    // Help page is a bit special because you can arrive there from multiple locations
    // By pressing back button (Navbar) we want to jump back to previous instead of something static.
    // Previous page might be any of form pages, or front page.
    // If help page is first loaded page, then we jump to root page.
    props: createProps(() => ({
      actions: [],
      previous: history.state.back === null ? 'root' : '__back'
    }))
  },
  {
    path: '/:date',
    name: 'date',
    components: {
      default: Day,
      nav: DateNavbar
    },
    props: createProps((route) => ({
      actions: [
        help(),
        editDate(fst(route.params.date)),
      ],
      previous: 'root',
      showPrevNext: true
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/:date/edit',
    name: 'info-edit',
    components: {
      default: FormDay,
      nav: DateNavbar
    },
    props: createProps(() => ({
      actions: [ help() ],
      previous: 'date',
      showPrevNext: true
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/:date/skydiver/:id',
    name: 'skydiver',
    components: {
      default: FormSkydiver,
      nav: DateNavbar
    },
    props: createProps((route) => ({
      actions: personPageActions(route),
      previous: 'date'
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/:date/student/:id',
    name: 'student',
    components: {
      default: FormStudent,
      nav: DateNavbar
    },
    props: createProps((route) => ({
      actions: personPageActions(route),
      previous: 'date'
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/:date/pilot/:id',
    name: 'pilot',
    components: {
      default: FormPilot,
      nav: DateNavbar
    },
    props: createProps((route) => ({
      actions: personPageActions(route),
      previous: 'date'
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/:date/support/:id',
    name: 'support',
    components: {
      default: FormSupport,
      nav: DateNavbar
    },
    props: createProps((route) => ({
      actions: personPageActions(route),
      previous: 'date'
    })),
    beforeEnter: validateDateParameter
  },
  {
    path: '/.*',
    name: 'not-found',
    redirect: 'root'
  }
]

export function createRouter(): Router {
  return createRouterBase({
    history,
    routes
  })
}
