import { DeletePersonPayload, LoadDayPayload } from "~/src/store"
import type { RouteLocationRaw } from 'vue-router'
import type { Payload } from 'vuex'
import type { TranslationKey } from "hto-api-client"

export type NavigationAction = {
  icon: string;
  titleKey: TranslationKey;
} & (
  {
    kind: 'action';
    onClick: (RouteLocationRaw | Payload)[]
  } | {
    kind: 'link';
    onClick: RouteLocationRaw
  }
)

export function editDate(date: string): NavigationAction {
  return {
    kind: 'link',
    icon: 'pencil-alt',
    titleKey: 'nav.editDate',
    onClick: {
      name: 'info-edit',
      params: { date }
    }
  }
}

export function deletePerson(date: string, id: string): NavigationAction {
  return {
    kind: 'action',
    icon: 'trash',
    titleKey: 'nav.cancelAttendance',
    onClick: [
      new DeletePersonPayload(date, id),
      {
        name: 'date',
        params: { date },
        replace: true
      },
      new LoadDayPayload(date)
    ]
  }
}

export function help(): NavigationAction {
  return {
    kind: 'link',
    icon: 'question-circle',
    titleKey: 'help.title',
    onClick: { name: 'help' }
  }
}
