import type { TFunction } from "hto-api-client"
import { useTranslationImpl } from "hto-api-client"
import type { InjectionKey } from "vue"
import { inject } from "vue"

type Translation = { t: TFunction }
const translationKey: InjectionKey<Translation> = Symbol()

export function createTranslation(...translationArguments: Parameters<typeof useTranslationImpl>): [InjectionKey<Translation>, Translation] {
  return [translationKey, { t: useTranslationImpl(...translationArguments) }]
}

export function useTranslation(): Translation {
  return inject(translationKey) ?? { t: () => "" }
}