import type { HtoConfiguration } from '~/src/store'
import logo from '~/src/assets/sdf-logo.svg'
import { StudentStageEnum } from 'hto-api-client'

export const SdfConfiguration = Object.freeze<HtoConfiguration>({
  logo,
  logoAlt: 'Skydive Finland',
  title: 'Skydive Finland',
  clubColor: '#27bbe1',
  clubName: 'sdf',
  studentColor: '#f28d17',
  studentStages: [
    StudentStageEnum.Aff13,
    StudentStageEnum.Aff47,
    StudentStageEnum.Basic,
  ],
  skydiverRoles: [
    "instructorAff",
    "instructorSl",
    "instructorCoach",
    "instructorRadio",
  ],
  translationsOverrides: {
    'skydiver.addOrEditLabel': {
      'fi': 'Kouluttaja',
      'en': 'Instructor',
    }
  }
})
