"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class DefaultApi extends runtime.BaseAPI {
    /**
     */
    async addOrUpdatePersonRaw(requestParameters, initOverrides) {
        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError('day', 'Required parameter requestParameters.day was null or undefined when calling addOrUpdatePerson.');
        }
        if (requestParameters.skydiveClubName === null || requestParameters.skydiveClubName === undefined) {
            throw new runtime.RequiredError('skydiveClubName', 'Required parameter requestParameters.skydiveClubName was null or undefined when calling addOrUpdatePerson.');
        }
        if (requestParameters.person === null || requestParameters.person === undefined) {
            throw new runtime.RequiredError('person', 'Required parameter requestParameters.person was null or undefined when calling addOrUpdatePerson.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.skydiveClubName !== undefined && requestParameters.skydiveClubName !== null) {
            headerParameters['Skydive-Club-Name'] = String(requestParameters.skydiveClubName);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // BearerAuth authentication
        }
        const response = await this.request({
            path: `/timeline/{day}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters.day))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.PersonToJSON)(requestParameters.person),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async addOrUpdatePerson(day, skydiveClubName, person, initOverrides) {
        await this.addOrUpdatePersonRaw({ day: day, skydiveClubName: skydiveClubName, person: person }, initOverrides);
    }
    /**
     */
    async deletePersonRaw(requestParameters, initOverrides) {
        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError('day', 'Required parameter requestParameters.day was null or undefined when calling deletePerson.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deletePerson.');
        }
        if (requestParameters.skydiveClubName === null || requestParameters.skydiveClubName === undefined) {
            throw new runtime.RequiredError('skydiveClubName', 'Required parameter requestParameters.skydiveClubName was null or undefined when calling deletePerson.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.skydiveClubName !== undefined && requestParameters.skydiveClubName !== null) {
            headerParameters['Skydive-Club-Name'] = String(requestParameters.skydiveClubName);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // BearerAuth authentication
        }
        const response = await this.request({
            path: `/timeline/{day}/{id}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters.day))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deletePerson(day, id, skydiveClubName, initOverrides) {
        await this.deletePersonRaw({ day: day, id: id, skydiveClubName: skydiveClubName }, initOverrides);
    }
    /**
     */
    async timelineDayGetRaw(requestParameters, initOverrides) {
        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError('day', 'Required parameter requestParameters.day was null or undefined when calling timelineDayGet.');
        }
        if (requestParameters.skydiveClubName === null || requestParameters.skydiveClubName === undefined) {
            throw new runtime.RequiredError('skydiveClubName', 'Required parameter requestParameters.skydiveClubName was null or undefined when calling timelineDayGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.skydiveClubName !== undefined && requestParameters.skydiveClubName !== null) {
            headerParameters['Skydive-Club-Name'] = String(requestParameters.skydiveClubName);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // BearerAuth authentication
        }
        const response = await this.request({
            path: `/timeline/{day}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters.day))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.DayFromJSON)(jsonValue));
    }
    /**
     */
    async timelineDayGet(day, skydiveClubName, initOverrides) {
        const response = await this.timelineDayGetRaw({ day: day, skydiveClubName: skydiveClubName }, initOverrides);
        return await response.value();
    }
    /**
     */
    async timelineDayPutRaw(requestParameters, initOverrides) {
        if (requestParameters.day === null || requestParameters.day === undefined) {
            throw new runtime.RequiredError('day', 'Required parameter requestParameters.day was null or undefined when calling timelineDayPut.');
        }
        if (requestParameters.skydiveClubName === null || requestParameters.skydiveClubName === undefined) {
            throw new runtime.RequiredError('skydiveClubName', 'Required parameter requestParameters.skydiveClubName was null or undefined when calling timelineDayPut.');
        }
        if (requestParameters.update === null || requestParameters.update === undefined) {
            throw new runtime.RequiredError('update', 'Required parameter requestParameters.update was null or undefined when calling timelineDayPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.skydiveClubName !== undefined && requestParameters.skydiveClubName !== null) {
            headerParameters['Skydive-Club-Name'] = String(requestParameters.skydiveClubName);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // BearerAuth authentication
        }
        const response = await this.request({
            path: `/timeline/{day}/status`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters.day))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.UpdateDayToJSON)(requestParameters.update),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async timelineDayPut(day, skydiveClubName, update, initOverrides) {
        await this.timelineDayPutRaw({ day: day, skydiveClubName: skydiveClubName, update: update }, initOverrides);
    }
    /**
     */
    async timelineGetRaw(requestParameters, initOverrides) {
        if (requestParameters.skydiveClubName === null || requestParameters.skydiveClubName === undefined) {
            throw new runtime.RequiredError('skydiveClubName', 'Required parameter requestParameters.skydiveClubName was null or undefined when calling timelineGet.');
        }
        const queryParameters = {};
        if (requestParameters.since !== undefined) {
            queryParameters['since'] = requestParameters.since;
        }
        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }
        if (requestParameters.countBack !== undefined) {
            queryParameters['count_back'] = requestParameters.countBack;
        }
        const headerParameters = {};
        if (requestParameters.skydiveClubName !== undefined && requestParameters.skydiveClubName !== null) {
            headerParameters['Skydive-Club-Name'] = String(requestParameters.skydiveClubName);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // BearerAuth authentication
        }
        const response = await this.request({
            path: `/timeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.DayFromJSON));
    }
    /**
     */
    async timelineGet(skydiveClubName, since, count, countBack, initOverrides) {
        const response = await this.timelineGetRaw({ skydiveClubName: skydiveClubName, since: since, count: count, countBack: countBack }, initOverrides);
        return await response.value();
    }
}
exports.DefaultApi = DefaultApi;
