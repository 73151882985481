"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Skydive organisator API
 * Author: Tuomas Vaherkoski
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentStageEnumToJSON = exports.StudentStageEnumFromJSONTyped = exports.StudentStageEnumFromJSON = exports.StudentStageEnum = void 0;
/**
 * Determines the kind of instructor the student requires
 * @export
 */
exports.StudentStageEnum = {
    None: 'none',
    Aff13: 'aff1_3',
    Aff47: 'aff4_7',
    Sl: 'sl',
    Basic: 'basic',
    Tandem: 'tandem'
};
function StudentStageEnumFromJSON(json) {
    return StudentStageEnumFromJSONTyped(json, false);
}
exports.StudentStageEnumFromJSON = StudentStageEnumFromJSON;
function StudentStageEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StudentStageEnumFromJSONTyped = StudentStageEnumFromJSONTyped;
function StudentStageEnumToJSON(value) {
    return value;
}
exports.StudentStageEnumToJSON = StudentStageEnumToJSON;
