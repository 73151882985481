<template>
    <div class="form-check">
        <label class="form-check-label">
            <input
              :data-cy="dataCy"
              type="checkbox"
              class="form-check-input"
              v-model="modelValue"
            />
            {{ label }}
        </label>
    </div>
</template>

<script setup lang="ts">
const modelValue = defineModel<boolean>({ required: true })
defineProps({
  label: {
    required: true,
    type: String,
  },
  dataCy: String
})
</script>
