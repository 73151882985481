<template>
  <div class="form-group">
    <form-label for="notes">{{ label }}</form-label>
    <div class="input-group">
      <input type="input" class="form-control" id="notes" :value="text" @input="$emit('update:text', $event.target.value)" maxlength="256" data-cy="notes" />
      <div
        class="input-group-append"
        :class="{ unhappy: unhappy, happy: !unhappy }"
      >
        <button class="btn" type="button" tabindex="-1" @click="toggle" :data-cy="dataCy">
          <icon v-if="unhappy" name="frown" data-cy="frown"></icon>
          <icon v-else name="smile" data-cy="smile"></icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import FormLabel from '~/src/components/FormLabel.vue'

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String
    },
    unhappy: {
      required: true,
      type: Boolean
    },
    label: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    const state = ref(props.unhappy)
    return {
      dataCy: computed(() => state.value ? 'toggle-happy' : 'toggle-unhappy'),
      theClass: computed(() => ({ unhappy: state.value, happy: !state.value })),
      toggle() {
        state.value = !state.value
        emit('update:unhappy', state.value)
      }
    }
  },
  emits: ['update:text', 'update:unhappy'],
  components: {
    FormLabel
  }
})
</script>

<style scoped>
.input-group-append.unhappy .btn {
  background: rgb(223, 10, 20);
  color: white;
}
</style>
