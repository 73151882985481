import type { MutationTree } from 'vuex'
import type { Day } from 'hto-api-client'
import type {
  RootState,
  SetDayMutation,
  SetScrollPositionMutation,
  SetLanguageMutation
} from '~/src/store/types'

enum UpsertResult { Update, Insert }

function upsertDay(days: Day[], day: Day): UpsertResult {
  const index = days.findIndex(d => d.date === day.date)
  if (index < 0) {
    days.push(day)
    return UpsertResult.Insert
  } else {
    days.splice(index, 1, day)
    return UpsertResult.Update
  }
}

export const mutations: MutationTree<RootState> = ({
  setDay(state, mutation: SetDayMutation) {
    const days = Array.isArray(mutation.day)
      ? mutation.day
      : [mutation.day]

    const newDays = state.days.slice()

    // Sorting is required only if new date was inserted. Otherwise it's already sorted.
    if(days.map(day => upsertDay(newDays, day)).some(r => r === UpsertResult.Insert)) {
      // order by date (ASC)
      newDays.sort((a, b) => a.date.localeCompare(b.date))
    }

    state.days = Object.freeze(newDays)
  },
  setScrollPosition(state, payload: SetScrollPositionMutation) {
    state.scrollTop = payload.scrollTop
  },
  setLanguage(state, payload: SetLanguageMutation) {
    state.lang = payload.language
    state.days = [] // clear localized unhappy / happy messages
    localStorage.setItem('language', payload.language)
  }
})
